import { slideDown, slideUp, slideStop, isVisible } from 'slide-anim'
import { closest, isSP } from '~/common/utils'

const OPEN_CLASS = '-open'
const OPEN_NAV_PANEL_CLASS = '-openNavPanel'
const OPEN_SELECTOR = '.js-footerNav-open'
const ITEM_SELECTOR = '.js-footerNav-item'
const PANEL_SELECTOR = '.js-footerNav-panel'
const TITLE_SELECTOR = '.js-footerNav-title'
const LIST_SELECTOR = '.js-footerNav-list'

/**
 * Header class
 */
export default class FooterNav {
  /**
   * [constructor description]
   * @return {[type]} [description]
   */
  constructor() {
    this.openEl = document.querySelectorAll(OPEN_SELECTOR)
    this.titleEl = document.querySelectorAll(TITLE_SELECTOR)
    this.titleButtonEl = document.querySelectorAll(`${TITLE_SELECTOR} > button`)
    this.listEl = document.querySelectorAll(LIST_SELECTOR)

    this.eventHandler()
    this.checkCurrent()
  }

  /**
   * 検索パネルが開いているか否か
   * @return boolean
   */
  get opensSearch() {
    return document.body.classList.contains(SEARCH_SELECTOR)
  }

  /**
   * メニューが開いているか否か
   * @return boolean
   */
  get opensMenu() {
    return document.body.classList.contains(OPEN_FOOTER_CLASS)
  }

  /**
   * イベント管理関数
   * @return {[type]} [description]
   */
  eventHandler() {
    ;[...this.openEl].map(el => {
      el.addEventListener('click', this.onOpenClick.bind(this))
    })
    ;[...this.titleEl].map(el => {
      el.addEventListener('click', this.onTitleClick.bind(this))
    })
  }

  onOpenClick(ev) {
    const parentItemEl = closest(
      ev.currentTarget,
      ITEM_SELECTOR.replace('.', '')
    )
    const panelEl = parentItemEl.querySelector(PANEL_SELECTOR)
    const opensPanel = parentItemEl.classList.contains(OPEN_NAV_PANEL_CLASS)

    if (opensPanel) {
      parentItemEl.classList.remove(OPEN_NAV_PANEL_CLASS)
    } else {
      parentItemEl.classList.add(OPEN_NAV_PANEL_CLASS)
    }

    panelEl.setAttribute('aria-expanded', !opensPanel)
  }

  onTitleClick(ev) {
    if (!isSP()) return true

    if (ev.target.type !== 'button') return

    const currentEl = ev.currentTarget
    const nextEl = currentEl.nextElementSibling
    const opensList = currentEl.classList.contains(OPEN_CLASS)

    if (nextEl) {
      if (opensList) {
        currentEl.classList.remove(OPEN_CLASS)
        slideStop(nextEl)
        slideUp(nextEl)
      } else {
        currentEl.classList.add(OPEN_CLASS)
        slideStop(nextEl)
        slideDown(nextEl)
      }

      nextEl.setAttribute('aria-expanded', !opensList)
    }
  }

  checkCurrent() {
    ;[...this.titleEl].map(el => {
      const ariaCurrentEl = el.querySelector('[aria-current]')

      if (!ariaCurrentEl) return

      const ariaCurrent = ariaCurrentEl.getAttribute('aria-current')

      if (ariaCurrent === 'page') {
        if (el.nextElementSibling) {
          el.nextElementSibling.style.display = 'block'
          el.nextElementSibling.classList.add(OPEN_NAV_PANEL_CLASS)
        }

        el.classList.add(OPEN_CLASS)
      }
    })

    //
    ;[...this.listEl].map(el => {
      const ariaCurrentEl = el.querySelector('[aria-current]')
      const itemEl = el.querySelector(ITEM_SELECTOR)

      if (!ariaCurrentEl) return

      const ariaCurrent = ariaCurrentEl.getAttribute('aria-current')

      if (ariaCurrent === 'page') {
        el.style.display = 'block'

        if (el.previousElementSibling) {
          el.previousElementSibling.classList.add(OPEN_CLASS)
        }

        if (itemEl) {
          itemEl.classList.add(OPEN_NAV_PANEL_CLASS)
        }
      }
    })
  }
}
