<script>
import { Component, Watch, Vue } from 'vue-property-decorator'
import YTPlayer from 'youtube-player'
import VimeoPlayer from '@vimeo/player'
import { debounce } from '~/common/utils'

import { BREAKPOINT_SP } from '~/common/config'
import * as apiConfig from '~/common/apiConfig'
import { wait, applyFontHandler } from '~/common/utils'
import * as cacheApi from '~/client/cacheApi'
import * as bannerTagApi from '~/client/bannerTagApi'
import SwiperSlider from '~/modules/SwiperSlider'
import { resolve } from 'dns'

const LOADING_CLASS = '-loading'
const FONT_OBSERVE_TARGET_SELECTOR = '.js-font-observeTarget'
const IMPORTANT_SELECTOR = '.js-important'
const HEADER_SELECTOR = '.js-header'
const NEWS_SELECTOR = '.c-news'

/**
 * フォントの取得
 */
const fetchFont = async params => {
  return await cacheApi.getFont(params)
}

/**
 * タグの取得
 */
const fetchTags = async () => {
  return await bannerTagApi.getTags()
}

/**
 * 画面サイズがスマホか否かを返す関数
 */
const isSP = () => {
  return window.innerWidth < BREAKPOINT_SP
}

@Component
export default class TopKv extends Vue {
  // タグ情報配列
  tags = []

  //
  fontHandlerList = []

  // タグが持つフォント情報を格納する配列
  httpFonts = {
    count: 0,
    fonts: []
  }

  // フォント情報
  font = apiConfig.defaultData.font

  // 現在選択されているタグID
  selectedID = 0

  // タグリストを開くか否か
  listOpens = false

  // 初回表示時に動画を再生するか否か
  initVideoPlay = false

  boxClass = {}
  elStyle = {}
  boxInnerStyle = {}
  fontSize = null

  /**
   * this.tagsの変更を監視
   */
  @Watch('tags')
  async changeTags(val) {
    let fonts = val.map(data => data.font)
    this.httpFonts = await fetchFont({ font_id: fonts.filter(id => id) })

    await wait(1500)
    this.updatePlayer()
  }

  /**
   * this.httpFontsの変更を監視
   */
  @Watch('httpFonts')
  changeFonts(resp) {
    this.fontHandlerList = resp.fonts.map(font => {
      const tag = this.tags.find(tag => tag.font === font.font_id)
      return {
        text: `${tag.name.charAt(0)}${font.display_font_name}`,
        fontFamily: `'${font.font_family_1}', ${font.font_family_2}`
      }
    })
  }

  /**
   * this.fontHandlerListの変更を監視
   */
  @Watch('fontHandlerList')
  changeFontHandlerList() {
    this.$nextTick(async () => {
      await wait(1000)
      applyFontHandler()
    })
  }

  /**
   * this.selectedTagDataの変更を監視
   */
  @Watch('selectedTagData')
  changeSelectedTagData(tag) {
    if (!tag.font) return
    this.font = this.httpFonts.fonts.find(font => tag.font === font.font_id)

    this.$nextTick(() => {
      applyFontHandler()
    })
  }

  /**
   * 現在選択中のタグが動画か否かを返す
   */
  get isMovie() {
    return (
      this.selectedTagData.type === 'youtube' ||
      this.selectedTagData.type === 'vimeo'
    )
  }

  /**
   * 現在選択中のタグ情報
   */
  get selectedTagData() {
    const data = this.tags.find(tag => tag.id === this.selectedID)
    return data ? data : apiConfig.defaultData.bannerTag
  }

  /**
   * タグ情報配列内から動画情報を検索
   */
  get videoData() {
    const data = this.tags.find(
      tag => tag.type === 'youtube' || tag.type === 'vimeo'
    )
    return data ? data : apiConfig.defaultData.bannerTag
  }

  async mounted() {
    this.importantEl = document.querySelector(IMPORTANT_SELECTOR)
    this.headerEl = document.querySelector(HEADER_SELECTOR)
    this.newsEl = document.querySelector(NEWS_SELECTOR)

    this.updateElStyle()
    this.$nextTick(() => {
      this.updateBoxClass()
      this.updateBoxInnerStyle()
    })

    // タグの取得を開始
    await this.onUpdateTags()

    if (isSP()) {
      let fonts = this.tags.map(data => data.font)
      this.httpFonts = await fetchFont({ font_id: fonts.filter(id => id) })
      this.selectedID = this.tags[0].id
    }

    // SP時に動画が先頭の場合にフラグをtrueにする
    this.initVideoPlay =
      (isSP() && this.tags[0].type === 'youtube') ||
      this.tags[0].type === 'vimeo'

    this.$nextTick(async () => {
      this.$el.classList.add('-mounted')
      this.carousel = new SwiperSlider(this.$refs.swiper)

      await wait(1000)
      this.updatePlayer()
      this.eventHandler()
    })
  }

  /**
   * イベント管理関数
   */
  eventHandler() {
    const mql = window.matchMedia(`screen and (max-width: ${BREAKPOINT_SP}px)`)
    mql.addListener(this.onMatchMedia.bind(this))

    this.carousel.swiper.on('slideChange', this.onSlideChange.bind(this))
    window.addEventListener('resize', debounce(this.onResize.bind(this), 100))

    //
    ;[...this.$el.querySelectorAll('.swiper-slide')].map(el => {
      el.addEventListener('click', this.onSlideClick.bind(this))
    })
  }

  onResize() {
    this.updateElStyle()
    this.$nextTick(() => {
      this.updateBoxClass()
      this.updateBoxInnerStyle()
    })
  }

  /**
   * ブレイクポイント変更時に呼ばれる関数
   */
  onMatchMedia({ matches }) {
    this.selectedID = isSP() ? this.tags[0].id : 0
    if (matches) {
      // SP
      this.elStyle = { height: null }
      this.boxInnerStyle = { width: null }
    } else {
      // PC
    }
  }

  /**
   * タグmouseover時に呼ばれる関数
   */
  onMouseover(tag) {
    this.selectedID = tag.id
    this.moviePlayController()
  }

  /**
   * リストオープンボタン押下時に呼ばれる関数
   */
  onListOpen() {
    this.listOpens = true
  }

  /**
   * リストクローズボタン押下時に呼ばれる関数
   */
  onListClose() {
    this.listOpens = false
  }

  /**
   * スライド変更時に呼ばれる関数（SPのみ）
   */
  onSlideChange() {
    const currentSlideEl = this.$refs.swiperSlides[
      this.carousel.swiper.realIndex
    ]
    if (currentSlideEl) {
      this.selectedID = Number(currentSlideEl.dataset.id)
    }
    this.movieStopController()
    this.moviePlayController()
  }

  /**
   * タグ情報を取得し更新する関数
   */
  async onUpdateTags() {
    this.selectedID = 0
    this.font = apiConfig.defaultData.font
    this.movieStopController()

    if (this.$refs.tags) {
      this.$refs.tags.classList.add(LOADING_CLASS)
      this.$refs.reload.classList.add(LOADING_CLASS)
    }

    await wait(this.tags.length * 80)

    return new Promise(async resolve => {
      this.tags = await fetchTags()
      resolve()

      this.$nextTick(async () => {
        await wait(100)
        if (this.$refs.tags) {
          this.$refs.tags.classList.remove(LOADING_CLASS)
          this.$refs.reload.classList.remove(LOADING_CLASS)
        }
      })
    })
  }

  /**
   * youtubeの再生準備完了後に呼ばれる関数
   */
  onYoutubeReady() {
    this.youtubeStop()
    this.youtubeMute()
    if (this.initVideoPlay) {
      this.youtubePlay()
      this.initVideoPlay = false
    }
  }

  onSlideClick(ev) {
    if (ev.currentTarget.classList.contains('swiper-slide-active')) {
      return true
    }

    ev.preventDefault()
    this.carousel.swiper.slideToLoop(
      Number(ev.currentTarget.getAttribute('data-swiper-slide-index'))
    )
  }

  /**
   * プレイヤーを更新する関数
   */
  updatePlayer() {
    if (!this.videoData) return

    this.movieStopController()

    if (this.videoData.type === 'youtube') {
      if (this.youtubePlayer) {
        this.youtubePlayer.loadVideoById(this.videoData.movie)
        this.youtubeMute()
      } else {
        this.youtubePlayer = new YTPlayer(this.$refs.youtube.id, {
          videoId: this.videoData.movie,
          playerVars: {
            autoplay: 0,
            playsinline: 1,
            playlist: this.videoData.movie,
            loop: 1,
            showinfo: 0,
            rel: 0,
            fs: 0
          }
        })
        this.youtubePlayer.on('ready', this.onYoutubeReady.bind(this))
      }
    } else if (this.videoData.type === 'vimeo') {
      if (this.vimeoPlayer) {
        this.vimeoPlayer.loadVideo(this.videoData.movie)
      } else {
        this.vimeoPlayer = new VimeoPlayer(this.$refs.vimeo.id, {
          id: this.videoData.movie,
          width: 640,
          controls: false,
          loop: true,
          muted: true,
          playsinline: true,
          title: false
        })
      }
    }
  }

  updateBoxClass() {
    this.boxClass = {
      '-vertical':
        this.$refs.box &&
        this.$refs.box.clientHeight > this.$refs.box.clientWidth,
      '-oblong':
        this.$refs.box &&
        this.$refs.box.clientHeight < this.$refs.box.clientWidth
    }
  }

  updateElStyle() {
    if (isSP()) {
      this.elStyle = { height: '' }
      return
    }
    const headerHeight = this.headerEl ? this.headerEl.clientHeight : 0
    const importantHeight = this.importantEl ? this.importantEl.clientHeight : 0
    const newsHeight = this.newsEl ? this.newsEl.clientHeight : 0
    const height =
      window.innerHeight - headerHeight - importantHeight - newsHeight
    this.elStyle = {
      height: `${height}px`
    }
  }

  updateBoxInnerStyle() {
    if (isSP()) {
      this.boxInnerStyle = { width: null }
      this.fontSize = ''
      return
    }

    const rect = this.$refs.box.getBoundingClientRect()
    const style = window.getComputedStyle(this.$refs.box, null)
    const paddingLeft = parseInt(style.getPropertyValue('padding-left'), 10)
    const paddingRight = parseInt(style.getPropertyValue('padding-right'), 10)
    const width = rect.height - paddingLeft - paddingRight

    if (
      this.$refs.box &&
      this.$refs.box.clientHeight < this.$refs.box.clientWidth
    ) {
      this.boxInnerStyle = {
        width: `${width}px`
      }
      this.fontSize = `${(width / window.innerWidth) * 100 - 1}vw`
    } else {
      this.boxInnerStyle = { width: null }
      this.fontSize = ''
    }
  }

  /**
   * 動画の再生をコントロールする関数
   */
  moviePlayController() {
    if (this.selectedTagData.type === 'youtube') {
      this.youtubePlay()
    } else if (this.selectedTagData.type === 'vimeo') {
      this.vimeoPlay()
    }
  }

  /**
   * 動画の停止をコントロールする関数
   */
  movieStopController() {
    this.youtubeStop()
    this.vimeoStop()
  }

  /**
   * youtubeを再生する関数
   */
  youtubePlay() {
    this.youtubePlayer.mute()
    this.youtubePlayer.seekTo(0)
    this.youtubePlayer.playVideo()
  }

  /**
   * youtubeを停止する関数
   */
  youtubeStop() {
    if (!this.youtubePlayer) return
    this.youtubePlayer.stopVideo()
  }

  /**
   * youtubeをミュートにする関数
   */
  youtubeMute() {
    if (!this.youtubePlayer) return
    this.youtubePlayer.mute()
  }

  /**
   * vimeoを再生する関数
   */
  vimeoPlay() {
    this.vimeoPlayer.setCurrentTime(0)
    this.vimeoPlayer.play()
  }

  /**
   * vimeoを停止する関数
   */
  vimeoStop() {
    if (!this.vimeoPlayer) return
    this.vimeoPlayer.pause()
  }
}
</script>
