import * as qs from 'querystringify'
import * as apiConfig from '~/common/apiConfig'

/**
 * サンプルテキストの取得
 */
export const getPhrases = () => {
  return fetch(apiConfig.endpoints.phrases, {
    method: 'GET'
  }).then(response => response.json())
}

/**
 * 書体一覧用サンプルテキストの取得
 */
export const getPhrasesFontsearch = () => {
  return fetch(apiConfig.endpoints.phrasesFontsearch, {
    method: 'GET'
  }).then(response => response.json())
}

/**
 * 字形の取得
 */
export const getShape = () => {
  return fetch(apiConfig.endpoints.shape, {
    method: 'GET'
  }).then(response => response.json())
}
