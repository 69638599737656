import { smoothScroll } from '~/common/utils'

const SELECTOR = '.js-scroll'

export const play = (id = null) => {
  if (!id) {
    return false
  }

  smoothScroll(document.getElementById(id.replace('#', '')).offsetTop)
}

/**
 * smooth scorll class
 */
export default class ScrollTo {
  /**
   * [constructor description]
   * @return {[type]} [description]
   */
  constructor() {
    this.el = document.querySelectorAll(SELECTOR)

    this.eventHandler()
  }

  eventHandler() {
    ;[...this.el].map(el => {
      el.addEventListener('click', this.handleTriggerClick.bind(this))
    })
  }

  handleTriggerClick(ev) {
    const currentEl = ev.currentTarget
    const id = currentEl.getAttribute('href')
    const targetEl = document.getElementById(id.replace('#', ''))

    if (id !== '#top' && !targetEl) {
      return true
    } else {
      // ev.preventDefault()
    }

    play(id)
  }
}
