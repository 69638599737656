import CustomEvent from 'custom-event'
import { EVENT_MENU_CLOSE, FONT_APPLY } from '~/common/config'

const customEventMenuClose = new CustomEvent(EVENT_MENU_CLOSE)
export const eventMenuClose = () => {
  document.dispatchEvent(customEventMenuClose)
}

const customEventFontApply = new CustomEvent(FONT_APPLY)
export const eventFontApply = el => {
  customEventFontApply.args = { el }
  document.dispatchEvent(customEventFontApply)
}
