import { closest, isSP } from '~/common/utils'

const OPEN_SHARE_CLASS = '-openShare'
const SHARE_LIST_CLASS = 'c-share__list'
const OPEN_SELECTOR = '.js-share-open'
const CLOSE_SELECTOR = '.js-share-close'
const PANEL_SELECTOR = '.js-share-panel'
const COPY_SELECTOR = '.js-share-copy'

/**
 * Header class
 */
export default class Share {
  /**
   * [constructor description]
   * @return {[type]} [description]
   */
  constructor() {
    this.openEl = document.querySelector(OPEN_SELECTOR)
    this.closeEl = document.querySelectorAll(CLOSE_SELECTOR)
    this.panelEl = document.querySelector(PANEL_SELECTOR)
    this.copyEl = document.querySelectorAll(COPY_SELECTOR)

    this.eventHandler()
  }

  /**
   * シェア要素が開いているか否か
   * @return boolean
   */
  get opensShare() {
    return document.body.classList.contains(OPEN_SHARE_CLASS)
  }

  get copySuccessText() {
    return isSP() ? 'コピーしました' : 'クリップボードにコピーしました'
  }

  /**
   * イベント管理関数
   * @return {[type]} [description]
   */
  eventHandler() {
    if (this.openEl) {
      this.openEl.addEventListener('click', this.open.bind(this))
    }

    ;[...this.closeEl].map(el => {
      el.addEventListener('click', this.close.bind(this))
    })

    if (this.panelEl) {
      this.panelEl.addEventListener('click', this.onPanelClick.bind(this))
    }

    ;[...this.copyEl].map(el => {
      el.setAttribute('data-default-label', el.getAttribute('aria-label'))
      el.addEventListener('click', this.onCopyClick.bind(this))
    })
  }

  open() {
    document.body.classList.add(OPEN_SHARE_CLASS)
    this.panelEl.setAttribute('aria-expanded', this.opensShare)
  }

  close() {
    document.body.classList.remove(OPEN_SHARE_CLASS)
    this.panelEl.setAttribute('aria-expanded', this.opensShare)
  }

  onCopyClick(ev) {
    ev.preventDefault()

    const currentEl = ev.currentTarget
    const input = document.createElement('input')
    input.readOnly = true
    input.value = location.href

    document.body.appendChild(input)

    input.select()
    input.focus()
    input.setSelectionRange(0, input.value.length)

    const command = document.execCommand('copy')
    if (command) {
      currentEl.setAttribute('aria-label', this.copySuccessText)

      clearTimeout(this.copySuccessMsgTimer)
      this.copySuccessMsgTimer = setTimeout(() => {
        currentEl.setAttribute(
          'aria-label',
          currentEl.getAttribute('data-default-label')
        )
      }, 3000)
    }

    document.body.removeChild(input)
  }

  onPanelClick(ev) {
    if (!closest(ev.target, SHARE_LIST_CLASS)) {
      this.close()
    }
  }
}
