import 'intersection-observer'
import { isSP } from '~/common/utils'

const SELECTOR = '.js-inView'

/**
 * 画面に入ったらclassをつけるclass
 */
export default class InView {
  /**
   * [constructor description]
   * @return {[type]} [description]
   */
  constructor() {
    this.el = document.querySelectorAll(SELECTOR)
    this.className = '-active'
    this.delay = 0
    this.threshold = 0.3
    this.remove = false
    this.removeSp = false

    this.eventHandler()
  }

  /**
   * イベント管理関数
   * @return {[type]} [description]
   */
  eventHandler() {
    const observer = new IntersectionObserver(
      this.handleIntersectionObserver.bind(this),
      {
        threshold: [0, 0.5, 1]
      }
    )
    ;[...this.el].map(el => {
      observer.observe(el)
    })
  }

  handleIntersectionObserver(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.addClass(entry.target)
      } else {
        this.removeClass(entry.target)
      }
    })
  }

  addClass(el = null) {
    if (!el) return

    const className = el.dataset.class ? el.dataset.class : this.className
    const delay = el.dataset.delay ? el.dataset.delay : this.delay

    setTimeout(() => {
      el.classList.add(className)
    }, delay)
  }

  removeClass(el = null) {
    if (!el) return

    const className = el.dataset.class ? el.dataset.class : this.className
    const delay = el.dataset.delay ? el.dataset.delay : this.delay
    const remove = el.dataset.remove ? el.dataset.remove : this.remove
    const removeSp = el.dataset.removeSp ? el.dataset.removeSp : this.removeSp

    if (!remove && !removeSp) return

    if (removeSp && !isSP()) return

    setTimeout(() => {
      el.classList.remove(className)
    }, delay)
  }
}
