import { API_BASE_URL } from '~/common/config'

export const endpoints = {
  bannerTags: `${API_BASE_URL}/fw/v1/banner_tags`,
  font: `${API_BASE_URL}/fw/v1/font`,
  fonts: `${API_BASE_URL}/fw/v1/fonts`,
  fontsCount: `${API_BASE_URL}/fw/v1/fonts_count`,
  fontSeries: `${API_BASE_URL}/fw/v1/font_series`,
  phrases: `/assets/json/specimen_phrases.json`,
  phrasesFontsearch: `/assets/json/specimen_phrases_fontsearch.json`,
  shape: `/assets/json/font_shape.json`
}

export const defaultData = {
  font: {
    category: '',
    characte_type: null,
    detail_url: '',
    display_font_name: '',
    font_family_1: '',
    font_family_2: '',
    font_id: '',
    font_series_id: '',
    font_style: '',
    image_convert_flg: '',
    language: '',
    manufacturers_wait: null,
    service_code: '',
    uses: ''
  },
  bannerTag: {
    id: 0,
    name: '',
    href: '',
    font: '',
    type: '',
    movie: ''
  }
}
