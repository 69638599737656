import { closest, isPCLargeMax } from '~/common/utils'

const OPEN_SEARCH_CLASS = '-openSearch'
const OPEN_FOOTER_CLASS = '-openFooter'
const SEARCH_INNER_CLASS = 'p-search__inner'
const HEADER_SELECTOR = '.js-header'
const FOOTER_SEARCH_SELECTOR = '#footer'
const SEARCH_OPEN_SELECTOR = '.js-header-searchOpen'
const SEARCH_CLOSE_SELECTOR = '.js-header-searchClose'
const MENU_BUTTON_SELECTOR = '.js-header-menuButton'
const IMPORTANT_SELECTOR = '.js-important'
const FS_SEARCH_SELECTOR = '.js-fs-search'
const FS_FILTER_SELECTOR = '.js-fs-filter'

/**
 * Header class
 */
export default class Header {
  /**
   * [constructor description]
   * @return {[type]} [description]
   */
  constructor() {
    this.headerEl = document.querySelector(HEADER_SELECTOR)
    this.footerEl = document.querySelector(FOOTER_SEARCH_SELECTOR)
    this.searchOpenEl = document.querySelector(SEARCH_OPEN_SELECTOR)
    this.searchCloseEl = document.querySelector(SEARCH_CLOSE_SELECTOR)
    this.searchEl = this.searchOpenEl
      ? document.getElementById(this.searchOpenEl.getAttribute('aria-controls'))
      : null
    this.menuButtonEl = document.querySelector(MENU_BUTTON_SELECTOR)
    this.importantEl = document.querySelector(IMPORTANT_SELECTOR)
    this.fsSearchEl = document.querySelector(FS_SEARCH_SELECTOR)

    this.eventHandler()
    this.updateImportantScreen()
  }

  /**
   * 検索パネルが開いているか否か
   * @return boolean
   */
  get opensSearch() {
    return document.body.classList.contains(OPEN_SEARCH_CLASS)
  }

  /**
   * メニューが開いているか否か
   * @return boolean
   */
  get opensMenu() {
    return document.body.classList.contains(OPEN_FOOTER_CLASS)
  }

  /**
   * イベント管理関数
   * @return {[type]} [description]
   */
  eventHandler() {
    if (this.searchOpenEl) {
      this.searchOpenEl.addEventListener('click', this.searchOpen.bind(this))
    }

    if (this.searchCloseEl) {
      this.searchCloseEl.addEventListener('click', this.searchClose.bind(this))
    }

    if (this.searchEl) {
      this.searchEl.addEventListener('click', this.searchClick.bind(this))
    }

    if (this.menuButtonEl) {
      this.menuButtonEl.addEventListener(
        'click',
        this.onMenuButtonClick.bind(this)
      )
    }

    if (this.importantEl) {
      window.addEventListener('resize', this.updateImportantScreen.bind(this))
      FONTPLUS.attachCompleteEvent(this.updateImportantScreen.bind(this))
    }
  }

  searchOpen() {
    this.menuClose()

    if (document.body.classList.contains(OPEN_SEARCH_CLASS)) {
      this.searchClose()
    } else {
      document.body.classList.add(OPEN_SEARCH_CLASS)
      this.searchOpenEl.setAttribute('aria-expanded', this.opensSearch)

      const inputEl = this.searchEl.querySelector('input[type="text"]')
      if (inputEl) {
        inputEl.focus()
      }
    }
  }

  searchClose() {
    document.body.classList.remove(OPEN_SEARCH_CLASS)
    this.searchOpenEl.setAttribute('aria-expanded', this.opensSearch)
  }

  searchClick(ev) {
    if (!closest(ev.target, SEARCH_INNER_CLASS)) {
      this.searchClose()
    }
  }

  onMenuButtonClick() {
    if (this.opensMenu) {
      this.menuClose()
    } else {
      this.menuOpen()
    }
  }

  menuOpen() {
    document.body.classList.add(OPEN_FOOTER_CLASS)
    this.menuButtonEl.setAttribute('aria-expanded', this.opensMenu)
  }

  menuClose() {
    document.body.classList.remove(OPEN_FOOTER_CLASS)
    this.menuButtonEl.setAttribute('aria-expanded', this.opensMenu)
  }

  updateImportantScreen() {
    if (!this.importantEl) return

    const importantHeight = this.importantEl.clientHeight
    const fsSearchEl = document.querySelector(FS_SEARCH_SELECTOR)
    const fsFilterEl = document.querySelector(FS_FILTER_SELECTOR)

    document.body.style.paddingTop = `${importantHeight}px`
    if (this.headerEl) {
      this.headerEl.style.marginTop = `${importantHeight}px`
    }
    if (this.footerEl) {
      this.footerEl.style.marginTop = `${importantHeight}px`
      this.footerEl.style.paddingBottom = `${importantHeight}px`
    }
    if (this.searchEl) {
      this.searchEl.style.marginTop = `${importantHeight}px`
    }
    if (fsSearchEl) {
      fsSearchEl.style.marginTop = `${importantHeight}px`
    }
    if (fsFilterEl) {
      fsFilterEl.style.marginTop = isPCLargeMax() ? '' : `${importantHeight}px`
    }
  }
}
