const LANG_ATTRIBUTE = 'data-lang';
const LANG_BUTTON_SELECTOR = `button[${LANG_ATTRIBUTE}]`
const CURRENT_CLASS = '-current';

/**
 * Lang class
 */
export default class Lang {
	constructor() {
		this.langButtons = Array.prototype.slice.call(document.querySelectorAll(LANG_BUTTON_SELECTOR));

		if (window.WOVN && WOVN.io && WOVN.io.isApiReady()) {
			this.setCurrentClass()
			this.eventHandler()
		} else {
			window.addEventListener('wovnApiReady', () => {
				this.setCurrentClass()
				this.eventHandler()
			})
		}
	}

	/**
	 * 現在の言語コード
	 * @return 'ja' | 'en'
	 */
	get currentLang() {
		return WOVN.io.getCurrentLang().code
	}

	/**
	 * 現在の言語のボタンにカレントクラスを付与
	 */
	setCurrentClass() {
		if (this.langButtons) {
			this.langButtons.forEach(button => {
				if (button.getAttribute(LANG_ATTRIBUTE) === this.currentLang) {
					button.classList.add(CURRENT_CLASS)
				} else {
					button.classList.remove(CURRENT_CLASS)
				}
			})
		}
	}

	/**
	 * イベント管理関数
	 */
	eventHandler() {
		if (this.langButtons) {
			this.langButtons.forEach(button => {
				button.addEventListener('click', this.changeLang.bind(this))
			})

			window.addEventListener('wovnLangChanged', this.setCurrentClass.bind(this))
		}
	}

	/**
	 * Wovnの言語切り替え
	 */
	changeLang(ev) {
		const targetLang = ev.target.getAttribute(LANG_ATTRIBUTE)
		if (targetLang === this.currentLang) {
			return
		}

		WOVN.io.changeLang(targetLang)
	}
}