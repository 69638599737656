import Emitter from 'events'
import * as urlencode from 'urlencode'
import * as qs from 'querystringify'
import FontFaceObserver from 'fontfaceobserver'
import split from './split'
import unicodeRange from './unicode_range'
import * as base64 from './base64'

export default class Font extends Emitter {
  constructor(handler, name, styleSheet) {
    super()

    this.handler = handler
    this.host = handler.options.host
    this.styleSheet = styleSheet
    this.name = name.replace(/"/g, '')
    this.set = new Set()
  }

  load(text, { fontDisplay = 'swap', fontFamily = this.name } = {}) {
    const subsetTarget = split(text, 1)
      .filter(this.filter.bind(this))
      .join('')

    // console.log('text =', text.replace(/[\r\n]/, ''), ', subsetTarget =', subsetTarget, ', cache =', this.set);

    if (!subsetTarget.length) {
      // console.log('All subsetTarget is already on cache. nothing to do');
      return Promise.resolve()
    }

    const splitted = split(subsetTarget, 500)

    return Promise.all(
      splitted.map(text => {
        const xhr = new XMLHttpRequest()

        // console.log(this.name == base64.decode(base64.encode(this.name)), this.name, base64.encode(this.name));
        // console.log(text == base64.decode(base64.encode(text)), text, base64.encode(text));

        const data = {
          fonts: [
            {
              fa: base64.encode(this.name),
              nn: 0 /* TODO */,
              lst: 0 /* TODO */,
              lsc: 0 /* TODO */,
              s: base64.encode(text)
            }
          ],
          con: this.handler.key,
          cha: 'UTF-8',
          dmode: document.documentMode || '',
          tm: +new Date(),
          size: 0 /* TODO */,
          aa: 1 /* TODO */,
          ab: 1 /* TODO */
        }

        xhr.open('POST', `${this.host}/accessor/reql`)
        xhr.setRequestHeader(
          'Content-Type',
          'application/x-www-form-urlencoded'
        )
        xhr.send(urlencode.stringify(data))

        const src =
          `${this.host}/accessor/reqf/` +
          qs.stringify({
            con: this.handler.key,
            cha: 'UTF-8',
            dmode: document.documentMode || '',
            aa: 1 /* TODO */,
            ab: 1 /* TODO */,
            fa: base64.encode(this.name),
            lst: 0 /* TODO */,
            lsc: 0 /* TODO */,
            s: base64.encode(text)
          })

        const decl = `
  font-family: '${fontFamily}';
  src: url('${src}');
  /* ${text} */
  unicode-range: ${unicodeRange(split(text, 1))};
  font-display: ${fontDisplay};
`
        this.styleSheet.add('@font-face', decl)

        return new FontFaceObserver(fontFamily).load(subsetTarget)
      })
    )
  }

  filter(char) {
    if (char == '\n') return false
    if (char == '\r') return false

    if (!this.set.has(char)) {
      this.set.add(char)
      return true
    }

    return false
  }
}
