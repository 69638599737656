import { slideDown, slideUp, slideStop, isVisible } from 'slide-anim'

const OPEN_CLASS = '-open'
const SELECTOR = '.js-accordion'
const PANEL_SELECTOR = '.js-accordion-panel'
const OPEN_SELECTOR = '.js-accordion-open'
const CLOSE_SELECTOR = '.js-accordion-close'
const TOGGLE_SELECTOR = '.js-accordion-toggle'

/**
 * Header class
 */
export default class Accordion {
  /**
   * [constructor description]
   * @return {[type]} [description]
   */
  constructor(el) {
    this.el = el
    this.panelEl = this.el.querySelector(PANEL_SELECTOR)
    this.openEl = this.el.querySelector(OPEN_SELECTOR)
    this.closeEl = this.el.querySelector(CLOSE_SELECTOR)
    this.toggleEl = this.el.querySelector(TOGGLE_SELECTOR)

    this.eventHandler()
  }

  get opens() {
    return this.el.classList.contains(OPEN_CLASS)
  }

  /**
   * イベント管理関数
   * @return {[type]} [description]
   */
  eventHandler() {
    if (this.openEl) {
      this.openEl.addEventListener('click', this.onOpenClick.bind(this))
    }
    if (this.closeEl) {
      this.closeEl.addEventListener('click', this.onCloseClick.bind(this))
    }
    if (this.toggleEl) {
      this.toggleEl.addEventListener('click', this.onToggleClick.bind(this))
    }
  }

  onOpenClick() {
    this.toggle()
  }

  onCloseClick() {
    this.toggle()
  }

  onToggleClick() {
    this.toggle()
  }

  toggle() {
    if (this.opens) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    this.el.classList.add(OPEN_CLASS)
    this.toggleEl.setAttribute('aria-expanded', 'true')
    this.toggleEl.setAttribute('aria-selected', 'true')
    this.panelEl.setAttribute('aria-hidden', 'false')
    slideStop(this.panelEl)
    slideDown(this.panelEl)
  }

  close() {
    this.el.classList.remove(OPEN_CLASS)
    this.toggleEl.setAttribute('aria-expanded', 'false')
    this.toggleEl.setAttribute('aria-selected', 'false')
    this.panelEl.setAttribute('aria-hidden', 'true')
    slideStop(this.panelEl)
    slideUp(this.panelEl)
  }
}
