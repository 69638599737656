import { offsetTop } from '~/common/utils'

const OPEN_CLASS = '-open'
const ACTIVE_CLASS = '-active'
const PANEL_SELECTOR = '.js-moreExpand-panel'
const OPEN_SELECTOR = '.js-moreExpand-open'
const CLOSE_SELECTOR = '.js-moreExpand-close'
const TOGGLE_SELECTOR = '.js-moreExpand-toggle'
const HEADER_SELECTOR = '.l-header'

/**
 * Header class
 */
export default class MoreExpand {
  /**
   * [constructor description]
   * @return {[type]} [description]
   */
  constructor(el) {
    this.el = el
    this.headerEl = document.querySelector(HEADER_SELECTOR)
    this.panelEl = this.el.querySelector(PANEL_SELECTOR)
    this.openEl = this.el.querySelector(OPEN_SELECTOR)
    this.closeEl = this.el.querySelector(CLOSE_SELECTOR)
    this.toggleEl = this.el.querySelector(TOGGLE_SELECTOR)
    this.dataRows = this.el.dataset.rows

    this.init()
    this.eventHandler()
  }

  get opens() {
    return this.el.classList.contains(OPEN_CLASS)
  }

  get headerHeight() {
    return this.headerEl ? this.headerEl.clientHeight : 0
  }

  init() {
    const computedStyle = getComputedStyle(this.panelEl)
    if (
      this.dataRows <
      Math.floor(
        this.panelEl.clientHeight / parseInt(computedStyle.lineHeight, 10)
      )
    ) {
      this.el.classList.add(ACTIVE_CLASS)
    }
  }

  /**
   * イベント管理関数
   * @return {[type]} [description]
   */
  eventHandler() {
    if (this.openEl) {
      this.openEl.addEventListener('click', this.toggle.bind(this))
    }
    if (this.closeEl) {
      this.closeEl.addEventListener('click', this.toggle.bind(this))
    }
    if (this.toggleEl) {
      this.toggleEl.addEventListener('click', this.toggle.bind(this))
    }
  }

  toggle() {
    if (this.opens) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    this.el.classList.add(OPEN_CLASS)
    this.toggleEl.classList.add(OPEN_CLASS)
  }

  close() {
    window.scrollTo(0, offsetTop(this.el) - this.headerHeight)
    this.el.classList.remove(OPEN_CLASS)
    this.toggleEl.classList.remove(OPEN_CLASS)
  }
}
