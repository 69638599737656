import MH from 'matchHeight'
import { debounce } from '~/common/utils'
import { isSP } from '~/common/utils'

export default class MatchHeight {
  constructor() {
    /**
     * data-mh属性が空のNodeList
     * @type NodeList
     */
    this.mhEl = document.querySelectorAll('[data-mh]')

    /**
     * data-mh-sp属性を持つ要素のNodeList
     * @type NodeList
     */
    this.mhElSP = document.querySelectorAll('[data-mh-sp]')

    /**
     * data-mh属性が"pc"のNodeList
     * @type NodeList
     */
    this.mhElPC = document.querySelectorAll('[data-mh-pc]')

    this.init()
    this.eventHandler()
  }

  /**
   * 初期設定関数
   */
  init() {
    this.matchHeightSP()
    this.matchHeightPC()
  }

  /**
   * イベント管理
   */
  eventHandler() {
    window.addEventListener('resize', debounce(this.init.bind(this), 50))
  }

  /**
   * SPのみ高さ揃えを実装する関数
   */
  matchHeightSP() {
    if (isSP()) {
      ;[...this.mhElSP].map(el => el.setAttribute('data-mh', ''))
      MH.init()
    } else {
      ;[...this.mhElSP].map(el => {
        el.removeAttribute('data-mh')
        el.style.minHeight = ''
      })
    }
  }

  /**
   * PCのみ高さ揃えを実装する関数
   */
  matchHeightPC() {
    if (!isSP()) {
      ;[...this.mhElPC].map(el => el.setAttribute('data-mh', ''))
      MH.init()
    } else {
      ;[...this.mhElPC].map(el => {
        el.removeAttribute('data-mh')
        el.style.minHeight = ''
      })
    }
  }
}
