<script>
import { Component, Watch, Model, Vue } from 'vue-property-decorator'
import { debounce } from '~/common/utils'

import { BREAKPOINT_SP_S } from '~/common/config'
import { applyFontHandler } from '~/common/utils'
import Select from '~/modules/Select'

const DEFAULT_FONT_SIZE = 84
const DEFAULT_BG_CLASS = 'bgRed'

@Component
export default class FsIntro extends Vue {
  // カラーパネルを開くか否か
  opensColorPanel = false

  // 背景色class名
  bgColorClass = DEFAULT_BG_CLASS

  // 背景画像ソース
  bgSrc = ''

  // フォントサイズ
  @Model('change', { type: Number, default: DEFAULT_FONT_SIZE }) fontSize

  // フォントサイズのプルダウン選択
  @Model('change', { type: Number, default: DEFAULT_FONT_SIZE })
  fontSizeSelect

  @Watch('fontSize')
  onFontSizeChanged(val) {
    this.fontSizeSelect = val
    this.closeColorPanel()
    this.$nextTick(() => {
      applyFontHandler()
    })
  }

  @Watch('fontSizeSelect')
  onFontSizeSelectChanged(val) {
    this.fontSize = val
    this.closeColorPanel()
  }

  get fontSizeStyle() {
    return this.fontSize ? `font-size: ${this.fontSize}px;` : ''
  }

  mounted() {
    this.eventHandler()
    new Select(this.$el)
    setTimeout(() => {
      applyFontHandler()
    }, 1000)
  }

  /**
   * イベント管理関数
   */
  eventHandler() {
    const mql = window.matchMedia(`(max-width: ${BREAKPOINT_SP_S}px)`)
    mql.addListener(this.onMatchMediaSPS.bind(this))
    this.onMatchMediaSPS(mql)
  }

  onMatchMediaSPS({ matches }) {
    this.fontSize = matches ? 0 : DEFAULT_FONT_SIZE
  }

  /**
   * タイトルテキストのkeyup時に呼ばれる関数
   */
  onTitleKeyUp(ev) {
    debounce(() => {
      applyFontHandler(this.$refs.title)
      this.updateShareUrl(ev.target.innerText)
    }, 1000)()
  }

  /**
   * カラーパネル開閉ボタンのクリック時に呼ばれる関数
   */
  onColorPanelToggle() {
    this.opensColorPanel = !this.opensColorPanel
  }

  /**
   * カラー選択ボタンクリック時に呼ばれる関数
   * @param {string} className
   */
  onChangeColor(className = DEFAULT_BG_CLASS) {
    this.bgColorClass = className
    this.bgSrc = ''
  }

  /**
   * 画像選択時時に呼ばれる関数
   * @param {object} ev
   */
  onFileChange(ev) {
    this.bgSrc = ''
    this.closeColorPanel()

    if (this.$refs.bgInput.files && this.$refs.bgInput.files[0]) {
      const reader = new FileReader()
      reader.onload = ev => {
        this.bgSrc = ev.target.result
        this.bgColorClass = ''
      }
      reader.readAsDataURL(this.$refs.bgInput.files[0])
    }
  }

  /**
   * カラーパネルを閉じる関数
   */
  closeColorPanel() {
    this.opensColorPanel = false
  }

  updateShareUrl(text = '') {
    const facebookEl = document.querySelector('.js-fs-facebook')
    const twitterEl = document.querySelector('.js-fs-twitter')

    if (facebookEl) {
      const facebookHref = facebookEl.getAttribute('href')
      const facebookUrl = new URL(facebookHref)
      const facebookParamsUrl = new URL(facebookUrl.searchParams.get('u'))
      facebookParamsUrl.searchParams.set('word', text)
      facebookUrl.searchParams.set('u', facebookParamsUrl.href)
      facebookEl.setAttribute('href', facebookUrl.href)
    }

    if (twitterEl) {
      const twitterHref = twitterEl.getAttribute('href')
      const twitterUrl = new URL(twitterHref)
      const twitterParamsUrl = new URL(twitterUrl.searchParams.get('url'))

      twitterParamsUrl.searchParams.set('word', text)
      twitterUrl.searchParams.set('text', text)
      twitterUrl.searchParams.set('url', twitterParamsUrl.href)
      twitterEl.setAttribute('href', twitterUrl.href)
    }

    history.pushState({}, '', '?word=' + text)
  }
}
</script>
