import * as qs from 'querystringify'
import * as apiConfig from '~/common/apiConfig'

/**
 * タグの取得
 */
export const getTags = () => {
  return fetch(apiConfig.endpoints.bannerTags, {
    method: 'GET'
  }).then(response => response.json())
}
