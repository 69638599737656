const isProd =
  location.hostname === 'stage.fontworks.co.jp' ||
  location.hostname === 'en.fontworks.co.jp' ||
  location.hostname === 'fontworks.co.jp'

export const API_BASE_URL = isProd
  ? '/wp-json'
  : 'http://localhost:30009/wp-json'

export const FONT_API_KEY = 'mkEq-JpOTGc%3D&aa=1&aa=1&ab=1'

export const BREAKPOINT_PC_L = 1200
export const BREAKPOINT_SP = 992
export const BREAKPOINT_SP_S = 768

export const ASSETS_PATH = '/assets'

export const EVENT_MENU_CLOSE = 'menu_close'
export const EVENT_FONTPLUS_ONREADY = 'FONTPLUS_ONREADY'
export const EVENT_FONT_APPLY = 'FONT_APPLY'
