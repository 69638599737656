import * as StackBlur from 'stackblur-canvas'
import UrlParse from 'url-parse'

/**
 * Header class
 */
export default class ImageBlur {
  /**
   * [constructor description]
   * @return {[type]} [description]
   */
  constructor(el) {
    this.el = el
    this.id = this.el.dataset.id
    this.radius = this.el.dataset.radius ? this.el.dataset.radius : 50

    if (this.id) {
      this.setBlur()
    }
  }

  /**
   * doc: https://github.com/flozz/StackBlur#api
   */
  setBlur() {
    const img = document.getElementById(this.id)
    const parse = new UrlParse(img.src, true)

    if (!img) return

    const downloadedImg = new Image()
    downloadedImg.addEventListener(
      'load',
      () => {
        StackBlur.image(downloadedImg, this.el, this.radius, false)
      },
      false
    )
    downloadedImg.src = parse.pathname

    if (img.width > img.height) {
      this.el.classList.add('-oblong')
    } else if (img.width < img.height) {
      this.el.classList.add('-vertical')
    } else {
      this.el.classList.add('-square')
    }
  }
}
