import Emitter from 'events'

export default class StyleSheet extends Emitter {
  constructor(el) {
    super()
    this.el = el
    this.sheet = el.sheet
    this.decls = []
  }

  add(selector, rule) {
    const { el, sheet, decls } = this
    const decl = `${selector} { ${rule} }${'\n\n'}`

    decls.push(decl)

    if (sheet.insertRule) {
      // W3C standard
      sheet.insertRule(decl, Math.max(0, sheet.cssRules.length - 1))
    } else if (sheet.addRule) {
      // Internet Explorer
      sheet.addRule(selector, rule)
    } else {
      // FIXME: Contents will be flicking.
      el.textContent = decls.join('')
    }

    // console.log('cssRules.length =', sheet.cssRules.length);
  }
}
