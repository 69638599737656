import 'core-js/es/set'
import 'core-js/features/array/includes'
import 'core-js/features/array/find'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'

import CustomEvent from 'custom-event'
import mediumZoom from 'medium-zoom'

import {
  FONT_API_KEY,
  EVENT_FONTPLUS_ONREADY,
  FONT_APPLY,
  BREAKPOINT_SP_S
} from '~/common/config'
import { applyFontHandler, closest, wait } from '~/common/utils'
import Header from '~/modules/Header'
import FooterNav from '~/modules/FooterNav'
import InView from '~/modules/InView'
import MatchHeight from '~/modules/MatchHeight'
import SwiperSlider from '~/modules/SwiperSlider'
import ScrollTo from '~/modules/ScrollTo'
import Share from '~/modules/Share'
import Back from '~/modules/Back'
import Select from '~/modules/Select'
import FontPlus from '~/modules/fontplus-handler'
import Accordion from '~/modules/Accordion'
import MoreExpand from '~/modules/MoreExpand'
import ImageBlur from '~/modules/ImageBlur'
import Lang from '~/modules/Lang'

import TopKv from '~/components/TopKv.vue'
import FontSearch from '~/components/FontSearch.vue'
import FsIntro from '~/components/FsIntro.vue'
import FsDetail from '~/components/FsDetail.vue'
import { windowWhen } from 'rxjs/operator/windowWhen'

const TOUCH_CLASS = '-touch'

document.addEventListener('DOMContentLoaded', () => {
  new Header()
  new FooterNav()
  new InView()
  new MatchHeight()
  new ScrollTo()
  new Share()
  new Back()
  new Select()
  new Lang()

  setFontPlusHandler()
  setYubinbangoRegion()
  setSearchResultFont()
  setCF7EventHandler()
  setMediumZoom()

  // トップキービジュアル
  const topKvEl = document.getElementById('vue-top-kv')
  if (topKvEl) {
    new TopKv({
      el: topKvEl
    })
  }

  // 書体見本一覧
  const fontsearchEl = document.getElementById('vue-fontsearch')
  if (fontsearchEl) {
    new FontSearch({
      el: fontsearchEl
    })
  }

  // 書体見本詳細キービジュアル
  const fsIntroEl = document.getElementById('vue-fs-intro')
  if (fsIntroEl) {
    new FsIntro({
      el: fsIntroEl
    })
  }

  // 書体見本詳細
  const fsDetailEl = document.getElementById('vue-fs-detail')
  if (fsDetailEl) {
    new FsDetail({
      el: fsDetailEl
    })
  }

  // カルーセル
  ;[...document.querySelectorAll('.js-swiper')].map(el => {
    new SwiperSlider(el)
  })

  // アコーディオン
  ;[...document.querySelectorAll('.js-accordion')].map(el => {
    new Accordion(el)
  })

  // 詳しくみるの開閉
  ;[...document.querySelectorAll('.js-moreExpand')].map(el => {
    new MoreExpand(el)
  })

  // 画像のぼかし
  ;[...document.querySelectorAll('.js-imageBlur')].map(el => {
    new ImageBlur(el)
  })

  const linkEl = document.querySelectorAll('a,button')
  ;[...linkEl].map(el => {
    el.addEventListener('touchstart', () => el.classList.add(TOUCH_CLASS))
    el.addEventListener('touchend', () => el.classList.remove(TOUCH_CLASS))
  })

  /**
   * なぜかinputに font-family: fontplus-unused; がついてしまうためfont-familyを削除する
   */
  setTimeout(() => {
    ;[...document.querySelectorAll('input')].map(el => {
      el.style.fontFamily = ''
    })
  }, 1000)
})

const setFontPlusHandler = () => {
  const dummyInput = document.getElementById('dummy-font-sample')
  const targetEl = document.querySelectorAll(
    '.js-dummy-font-sample,.js-font-observeTarget'
  )

  const fontplus = new FontPlus({
    code: FONT_API_KEY,
    host: 'https://webfont.fontplus.jp',
    observeTarget: [...targetEl],
    applyAfterDOMContentLoaded: false
  })

  fontplus.attach()
  fontplus.on(EVENT_FONTPLUS_ONREADY, () => {
    if (dummyInput) {
      dummyInput.addEventListener('keyup', () => {
        setFont(dummyInput.value)
      })
    }

    function setFont(value = '') {
      if (dummySampleText) {
        ;[...dummySampleText].map(el => {
          if (value) {
            el.innerText = value
          }
        })
      }
    }

    document.addEventListener(FONT_APPLY, ev => {
      fontplus.apply(ev.args.el)
    })
  })
}

const setYubinbangoRegion = () => {
  const target = document.querySelector('.h-adr .p-region[type="hidden"]')
  const selct = document.querySelector('.h-adr select.p-region')

  if (!target || !selct) return

  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      if (target.value) {
        selct.value = target.value
        selct.dispatchEvent(new CustomEvent('change'))
      }
    })
  })
  const config = { attributes: true, childList: true, characterData: true }

  observer.observe(target, config)
}

const setSearchResultFont = () => {
  const target = document.getElementById('ss-main')
  let form = null

  if (!target) return

  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      applyFontHandler()

      if (!form) {
        form = document.getElementById('ss-form')
      } else {
        form.addEventListener('submit', () => {
          setTimeout(() => {
            applyFontHandler()
          }, 1000)
        })
      }
    })
  })
  const config = { attributes: true, childList: true, characterData: true }

  observer.observe(target, config)
}

const setCF7EventHandler = () => {
  const wpcf7Elm = document.querySelector('.wpcf7')

  if (wpcf7Elm) {
    wpcf7Elm.addEventListener(
      'wpcf7submit',
      async ev => {
        await wait(1000)

        const selectEl = document.querySelectorAll('select.wpcf7c-conf')

        ;[...selectEl].map(el => {
          const parentEl = closest(el, 'c-select')
          if (parentEl) {
            parentEl.classList.add('-conf')
          }
        })
      },
      false
    )

    wpcf7Elm.addEventListener('wpcf7invalid', applyFont)
    wpcf7Elm.addEventListener('wpcf7spam', applyFont)
    wpcf7Elm.addEventListener('wpcf7mailsent', applyFont)
    wpcf7Elm.addEventListener('wpcf7mailfailed', applyFont)
  }

  async function applyFont() {
    await wait(1000)
    applyFontHandler()
  }
}

const setMediumZoom = () => {
  const importantEl = document.querySelector('.js-important')
  const headerEl = document.querySelector('.js-header')
  let margin = 0

  if (importantEl) {
    margin += parseInt(importantEl.clientHeight, 10)
  }

  if (headerEl) {
    margin += parseInt(headerEl.clientHeight, 10)
  }

  mediumZoom('[data-zoomable]', {
    margin: window.innerWidth < BREAKPOINT_SP_S ? 4 : 32,
    background: 'rgba(0,0,0,.5)',
    scrollOffset: 0
  })
}

window.initMap = () => {
  ;[...document.querySelectorAll('.js-map')].map(el => {
    const myLatLng = {
      lat: Number(el.dataset.lat),
      lng: Number(el.dataset.lng)
    }
    const map = new google.maps.Map(el, {
      center: myLatLng,
      zoom: 16,
      styles: [
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [
            {
              saturation: -100
            },
            {
              lightness: 30
            }
          ]
        },
        {
          featureType: 'landscape',
          elementType: 'labels',
          stylers: [
            {
              saturation: -100
            }
          ]
        },
        {
          featureType: 'poi',
          stylers: [
            {
              saturation: -100
            },
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#d8e8dd'
            }
          ]
        },
        {
          featureType: 'road',
          stylers: [
            {
              saturation: -100
            },
            {
              lightness: 50
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [
            {
              saturation: -55
            }
          ]
        }
      ]
    })
    const marker = new google.maps.Marker({
      position: myLatLng,
      map
    })
  })
}
