import * as qs from 'querystringify'
import * as apiConfig from '~/common/apiConfig'

/**
 * フォントデータの件数取得
 * @param payload {Object}
 */
export const getFontsCount = (payload = {}) => {
  return fetch(`${apiConfig.endpoints.fontsCount}?${qs.stringify(payload)}`, {
    method: 'GET'
  }).then(response => response.json())
}

/**
 * フォント情報の取得
 * @param payload {Object}
 */
export const getFont = (payload = {}) => {
  return fetch(`${apiConfig.endpoints.font}?${qs.stringify(payload)}`, {
    method: 'GET'
  }).then(response => response.json())
}

/**
 * フォント情報の複数取得
 * @param payload {Object}
 */
export const getFonts = (payload = {}) => {
  const defaultPayload = {
    offset: 0,
    limit: 21
  }
  const mergePayload = Object.assign(defaultPayload, payload)

  return fetch(`${apiConfig.endpoints.fonts}?${qs.stringify(mergePayload)}`, {
    method: 'GET'
  }).then(response => response.json())
}

/**
 * フォントシリーズ情報の取得
 * @param payload {Object}
 */
export const getSeries = (payload = {}) => {
  return fetch(`${apiConfig.endpoints.fontSeries}?${qs.stringify(payload)}`, {
    method: 'GET'
  }).then(response => response.json())
}
