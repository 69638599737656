const BACK_SELECTOR = '.js-back'
const BUTTON_SELECTOR = '.js-back-button'

/**
 * Header class
 */
export default class Back {
  /**
   * [constructor description]
   * @return {[type]} [description]
   */
  constructor() {

    this.backEl = document.querySelector(BACK_SELECTOR)
    this.buttonEl = document.querySelector(BUTTON_SELECTOR)
    this.isHistory = history.length >= 2

    if (!this.isHistory) {
      this.deleteBack()
    } else {
      this.eventHandler()
    }
  }

  /**
   * イベント管理関数
   * @return {[type]} [description]
   */
  eventHandler() {
    if (this.buttonEl) {
      this.buttonEl.addEventListener('click', this.back.bind(this))
    }
  }

  deleteBack() {
    this.backEl.remove()
  }

  back() {
    history.back()
  }
}
