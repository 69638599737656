import { closest, isSP } from '~/common/utils'
import * as scrollTo from '~/modules/ScrollTo'

const SELECTOR = '.js-select'
const TEXT_SELECTOR = '.js-select-text'

/**
 * Select class
 */
export default class Select {
  /**
   * [constructor description]
   * @return {[type]} [description]
   */
  constructor(parentEl = document) {
    this.el = parentEl.querySelectorAll(SELECTOR)

    this.eventHandler()
  }

  /**
   * イベント管理関数
   * @return {[type]} [description]
   */
  eventHandler() {
    ;[...this.el].map(el => {
      const selectEl = el.querySelector('select')
      if (selectEl) {
        selectEl.addEventListener('change', this.onChange.bind(this))
      }
    })
  }

  onChange(ev) {
    ev.preventDefault()

    const currentEl = ev.currentTarget
    const currentOption = currentEl.options[currentEl.selectedIndex]
    const value = currentOption.innerText || currentOption.value
    const dataHref = currentOption.dataset.href
    const parentEl = closest(ev.currentTarget, SELECTOR.replace('.', ''))
    const textEl = parentEl ? parentEl.querySelector(TEXT_SELECTOR) : null

    if (dataHref) {
      if (dataHref.charAt(0) === '#') {
        scrollTo.play(dataHref.replace('#', ''))
      } else {
        return (location.href = dataHref)
      }
    }

    if (textEl) {
      textEl.innerText = value
    }
  }
}
