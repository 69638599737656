<script>
import { Component, Watch, Model, Vue } from 'vue-property-decorator'
import * as qs from 'querystringify'
import { createBrowserHistory } from 'history'
import { slideDown, slideUp, slideStop, isVisible } from 'slide-anim'
import { debounce, isEmpty } from '~/common/utils'

import { BREAKPOINT_PC_L, BREAKPOINT_SP_S } from '~/common/config'
import {
  isPCLargeMax,
  applyFontHandler,
  wait,
  smoothScroll
} from '~/common/utils'
import * as dataApi from '~/client/dataApi'
import * as cacheApi from '~/client/cacheApi'
import Select from '~/modules/Select'
import Share from '~/modules/Share'
import MatchHeight from '~/modules/MatchHeight'

const matchHeight = new MatchHeight()

const STORAGE_BOOKMARK_IDS = 'FW_bookmark_ids'
const HEADER_SEARCH_CLASS = '.js-header'
const IMPORTANT_SELECTOR = '.js-important'
const FS_SEARCH_SELECTOR = '.js-fs-search'
const HIDE_CLASS = '-hide'
const OPEN_CLASS = '-open'
const ADDED_CLASS = '-added'
const GRID_DEFAULT_FONT_SIZE = 40
const LIST_DEFAULT_FONT_SIZE = 64

const history = createBrowserHistory()
const defaultParams = {
  pages: 1,
  language: '0010',
  category: '',
  thickness: '',
  useCase: '',
  caseCat: '',
  characterType: '',
  fontName: '',
  orderBy: ''
}
const currentParams = Object.assign(
  JSON.parse(JSON.stringify(defaultParams)),
  qs.parse(location.search)
)

/**
 * 文字列を特定の文字列で分割し配列にする関数
 * @param {string} str
 * @param {string} separator
 */
const converArray = (str = '', separator = ',') => {
  return str.split(separator).filter(v => v)
}

@Component
export default class FontSearch extends Vue {
  //
  httpState = 'loading'

  // 表示数
  viewNum = 21

  // URLパラメーターの情報
  currentParams = currentParams

  // 現在のページ数
  currentPage = 'pages' in currentParams ? Number(currentParams.pages) : 1

  // フォントデータ
  httpFonts = {
    count: 0,
    fonts: []
  }

  // あとで見るに追加されているフォントデータ
  httpBookmarkFonts = {
    count: 0,
    fonts: []
  }

  sampleTextSelectData = []

  // ページタイプ: 書体一覧、あとで見る
  pageType = 'list'

  // ビュータイプ: grid、list
  viewType = 'grid'

  // sessionStorageのあとで見るID配列
  bookmarkIds = this.getBookMarkStorage()

  // フィルターパネルを表示するか否か
  opensFilter = false

  // フィルタリングされているか否か
  hasFilter = false

  // サンプルテキスト
  @Model('change', { type: String, default: '' }) sampleText

  // サンプルテキストプルダウン
  @Model('change', { type: String, default: '' }) sampleTextSelect

  // フォントサイズ
  @Model('change', { type: String, default: GRID_DEFAULT_FONT_SIZE }) fontSize

  // フォントサイズのプルダウン選択
  @Model('change', { type: String, default: GRID_DEFAULT_FONT_SIZE })
  fontSizeSelect

  // 言語選択プルダウン
  @Model('change', { type: String, default: currentParams.language }) language

  // カテゴリー
  @Model('change', {
    type: Array,
    default: () => converArray(currentParams.category)
  })
  category

  // ウェイト
  @Model('change', {
    type: Array,
    default: () => converArray(currentParams.thickness)
  })
  thickness

  // おすすめ
  @Model('change', {
    type: Array,
    default: () => converArray(currentParams.useCase)
  })
  useCase

  // 使用事例
  @Model('change', {
    type: Array,
    default: () => converArray(currentParams.caseCat)
  })
  caseCat

  // 文字セット
  @Model('change', {
    type: Array,
    default: () => converArray(currentParams.characterType)
  })
  characterType

  // フォント名検索インプット
  @Model('change', {
    type: Array,
    default: currentParams.fontName
  })
  fontName

  // 並び替えプルダウン
  @Model('change', {
    type: Array,
    default: currentParams.orderBy
  })
  orderBy

  @Watch('httpFonts')
  onFontsChanged() {
    this.$nextTick(() => {
      applyFontHandler()
    })
  }

  @Watch('fontSize')
  onFontSizeChanged(val) {
    this.fontSizeSelect = val
  }

  @Watch('fontSizeSelect')
  onFontSizeSelectChanged(val) {
    this.fontSize = val
  }

  @Watch('sampleText')
  onSampleTextChanged(val) {
    this.$nextTick(debounce(this.applyFontSampleText.bind(this), 500))
  }

  @Watch('sampleTextSelect')
  onSampleTextSelectChanged(val) {
    this.sampleText = val
  }

  @Watch('language')
  onLanguageChanged(val) {
    location.href = `?language=${val}`
  }

  @Watch('category')
  onCategoryChanged(val) {
    this.clearPageNum()
    this.updateFonts()
    this.hasFilter = !val || val.length > 0
  }

  @Watch('thickness')
  onThicknessChanged(val) {
    this.clearPageNum()
    this.updateFonts()
    this.hasFilter = !val || val.length > 0
  }

  @Watch('useCase')
  onUseCaseChanged(val) {
    this.clearPageNum()
    this.updateFonts()
    this.hasFilter = !val || val.length > 0
  }

  @Watch('caseCat')
  onCaseCatChanged(val) {
    this.clearPageNum()
    this.updateFonts()
    this.hasFilter = !val || val.length > 0
  }

  @Watch('characterType')
  onCharacterTypeChanged(val) {
    this.clearPageNum()
    this.updateFonts()
    this.hasFilter = !val || val.length > 0
  }

  @Watch('fontName')
  onFontNameChanged(val) {
    if (this.httpState === 'loading') return

    debounce(() => {
      this.clearPageNum()
      this.updateFonts()
      applyFontHandler()
    }, 200)()
  }

  @Watch('orderBy')
  onOrderByChanged(val) {
    this.clearPageNum()
    this.updateFonts()
    // this.hasFilter = !val || val.length > 0
  }

  get maxCount() {
    return this.httpFonts.count
  }

  get startNum() {
    const num = (this.currentPage - 1) * this.viewNum
    return num ? (this.currentPage - 1) * this.viewNum + 1 : 1
  }

  get endNum() {
    return this.startNum + this.httpFonts.fonts.length - 1
  }

  get pageNum() {
    return Math.ceil(this.maxCount / this.viewNum)
  }

  get pagerList() {
    const list = []
    const pagerNum = Math.ceil(this.maxCount / this.viewNum)
    const listNum = pagerNum > 6 ? 6 : pagerNum + 1
    const halfNum = listNum / 2
    let _i = 0

    for (let i = 1; i < listNum; i++) {
      if (this.currentPage < halfNum) {
        _i = i
      } else if (this.currentPage + halfNum > this.pageNum) {
        _i = this.pageNum - (listNum - 1) + i
      } else {
        _i = i + (this.currentPage - halfNum)
      }
      list.push(_i)
    }

    return list
  }

  get fontsPayload() {
    return {
      offset: this.viewNum * (this.currentPage - 1),
      language: this.language,
      category: this.category,
      thickness: this.thickness,
      useCase: this.useCase,
      caseCat: this.caseCat,
      characterType: this.characterType,
      fontName: this.fontName,
      orderBy: this.orderBy
    }
  }

  get sampleTextSelectOptions() {
    const list = ['']

    if (this.sampleTextSelectData.length === 0) {
      return list
    }

    const data = this.sampleTextSelectData.languages.find(
      v => v.code === this.language
    )
    if (!data) {
      return list
    }

    data.phrases.forEach(v => {
      list.push(v.text)
    })

    return list
  }

  get bookmarkFonts() {
    let fonts = []
    if (this.fontName) {
      fonts = this.httpBookmarkFonts.fonts.filter(font => {
        return font.display_font_name.indexOf(this.fontName) >= 0
      })
    } else {
      fonts = this.httpBookmarkFonts.fonts
    }
    return fonts
  }

  get hasBookmarkMultiLang() {
    let languages = this.httpBookmarkFonts.fonts.map(font => font.language)
    languages = languages.filter((x, i, self) => {
      return self.indexOf(x) === i
    })
    return languages.length > 1
  }

  async created() {
    ;[this.httpFonts, this.httpBookmarkFonts, this.series] = await Promise.all([
      cacheApi.getFonts(this.fontsPayload),
      cacheApi.getFont({ font_id: this.bookmarkIds }),
      cacheApi.getSeries()
    ])
    this.httpState = 'ready'
  }

  async mounted() {
    this.sampleTextSelectData = await dataApi.getPhrasesFontsearch()
    this.sampleText = this.getGridDefaultSampleText()

    new Select(this.$el)
    new Share()

    this.eventHander()
    this.updateFilterStyle()

    this.$nextTick(() => {
      this.$refs.fontsearch.classList.add('-mounted')
      // applyFontHandler()
    })
  }

  eventHander() {
    const mql = window.matchMedia(`(max-width: ${BREAKPOINT_PC_L}px)`)
    mql.addListener(this.onMatchMediaPCL.bind(this))
    this.onMatchMediaPCL(mql)

    const mql2 = window.matchMedia(`(max-width: ${BREAKPOINT_SP_S}px)`)
    mql2.addListener(this.onMatchMediaSPS.bind(this))
    this.onMatchMediaSPS(mql2)

    window.addEventListener('scroll', this.onScroll.bind(this))
  }

  onMatchMediaPCL({ matches }) {
    if (matches) {
      this._fontSize = this.fontSize
      this.fontSize = 0
    } else {
      if (this._fontSize) {
        this.fontSize = this._fontSize
      }
    }
    this.updateFilterStyle()
  }

  onMatchMediaSPS({ matches }) {
    if (matches) {
      this.viewType = 'list'
      this.sampleText = this.sampleTextSelectOptions[1]
    } else {
      this.viewType = 'grid'
      this.sampleText = this.getGridDefaultSampleText()
    }
  }

  onScroll() {
    this.updateFilterStyle()
  }

  noRest() {
    this.currentPage = 1
    // this.language = defaultParams.language
    this.category = []
    this.thickness = []
    this.useCase = []
    this.caseCat = []
    this.characterType = []
    this.fontName = ''
  }

  onFontNameDelete() {
    this.fontName = ''
    this.updateFonts()
  }

  toggleFilterPanel(ev) {
    const currentEl = ev.currentTarget
    const isOpen = currentEl.getAttribute('aria-expanded') === 'true'
    const targetId = currentEl.getAttribute('aria-controls')
    const targetEl = document.getElementById(
      currentEl.getAttribute('aria-controls')
    )

    if (!targetEl) return

    if (isOpen) {
      currentEl.setAttribute('aria-expanded', false)
      currentEl.setAttribute('aria-selected', false)
      targetEl.setAttribute('aria-hidden', true)
      slideStop(targetEl)
      slideUp(targetEl)
    } else {
      currentEl.setAttribute('aria-expanded', true)
      currentEl.setAttribute('aria-selected', true)
      targetEl.setAttribute('aria-hidden', false)
      slideStop(targetEl)
      slideDown(targetEl)
    }
  }

  toggleStyle(ev, font) {
    const currentEl = ev.currentTarget
    const isOpen = currentEl.getAttribute('aria-expanded') === 'true'
    const targetId = currentEl.getAttribute('aria-controls')
    const targetEl = document.getElementById(
      currentEl.getAttribute('aria-controls')
    )

    if (!targetEl) return

    if (isOpen) {
      currentEl.classList.remove(OPEN_CLASS)
      currentEl.setAttribute('aria-expanded', false)
      currentEl.setAttribute('aria-selected', false)
      targetEl.setAttribute('aria-hidden', true)
      slideStop(targetEl)
      slideUp(targetEl)
    } else {
      currentEl.classList.add(OPEN_CLASS)
      currentEl.setAttribute('aria-expanded', true)
      currentEl.setAttribute('aria-selected', true)
      targetEl.setAttribute('aria-hidden', false)
      slideStop(targetEl)
      slideDown(targetEl)
      applyFontHandler()
    }
  }

  togglePage(type = 'list') {
    window.scrollTo(0, 0)
    this.pageType = type

    if (this.hasBookmarkMultiLang && this.pageType === 'bookmark') {
      this.sampleText = ''
    }
  }

  toggleView(type = 'grid') {
    const isGrid = type === 'grid'

    this.viewType = type
    this.fontSize = isGrid ? GRID_DEFAULT_FONT_SIZE : LIST_DEFAULT_FONT_SIZE

    if (isGrid) {
      this.$refs.stylePanel.forEach(el => el.setAttribute('aria-hidden', true))
      if (!this.hasBookmarkMultiLang && this.pageType === 'list') {
        this.sampleText = this.getGridDefaultSampleText()
      }
    } else {
      if (!this.sampleText) {
        this.sampleText =
          this.hasBookmarkMultiLang && this.pageType === 'bookmark'
            ? ''
            : this.sampleTextSelectOptions[1]
      } else if (this.hasBookmarkMultiLang && this.pageType === 'bookmark') {
        this.sampleText = ''
      }
    }

    matchHeight.init()
  }

  toggleFilter() {
    if (isPCLargeMax()) {
      window.scrollTo(0, 0)
    }
    this.opensFilter = !this.opensFilter
  }

  addBookmark(font = '') {
    if (!font) return

    if (this.addedBookmark(font.font_id)) {
      this.bookmarkIds = this.bookmarkIds.filter(_id => _id !== font.font_id)
      this.httpBookmarkFonts.fonts = this.httpBookmarkFonts.fonts.filter(
        _font => _font.font_id !== font.font_id
      )
    } else {
      const ids = this.getBookMarkStorage()

      ids.push(font.font_id)
      this.bookmarkIds = [...new Set(ids)]
      this.httpBookmarkFonts.fonts.push(font)
      // this.updateBookmartFonts()
    }

    sessionStorage.setItem(STORAGE_BOOKMARK_IDS, this.bookmarkIds.toString())
  }

  addedBookmark(id = '') {
    if (!id) return

    return this.bookmarkIds.includes(id)
  }

  getBookMarkStorage() {
    const getIds = sessionStorage.getItem(STORAGE_BOOKMARK_IDS)
    return getIds ? getIds.split(',').map(id => id) : []
  }

  getFontFamilyStyleVal(font) {
    if (!font) return ''

    if (font.font_family_1) {
      return `"${font.font_family_1.replace('"', "'")}"`
    } else if (font.font_family_2) {
      return `${font.font_family_2}`
    }

    return ''
  }

  getSampleTextStyle(font) {
    const seriesData = this.getSeriesFontsList(font.font_series_id)
    const series = seriesData ? seriesData[0].series : null

    let styles = {
      fontSize: this.fontSize ? `${this.fontSize}px` : ''
    }

    if (series) {
      if (Number(series.extend)) {
        styles.transform = `scaleY(${Number(series.extend) * 0.01})`
      }
      if (Number(series.condensed)) {
        styles.transform = `scaleX(${Number(series.condensed) * 0.01})`
      }
    }

    if (font) {
      styles.fontFamily = this.getFontFamilyStyleVal(font)
    }

    return styles
  }

  getSeriesFontsList(font_series_id = 0) {
    const series = this.series.filter(data => {
      return data.series.font_series_id === font_series_id
    })

    if (series) {
      // 同じ manufacturers_waitのものがあったら後半を採用する
      const fonts = []
      series[0].fonts.forEach((font, i) => {
        fonts[font.manufacturers_wait] = font
      })
      series[0].fonts = Object.keys(fonts).map(key => fonts[key])
    }

    return series
  }

  getSeriesFontsCount(font_series_id = 0) {
    const series = this.getSeriesFontsList(font_series_id)
    return series ? series[0].fonts.length : 0
  }

  getSeriesFonts(font_series_id = 0) {
    const series = this.getSeriesFontsList(font_series_id)
    return series ? series[0].fonts : []
  }

  getSeriesName(font_series_id = 0) {
    const series = this.getSeriesFontsList(font_series_id)
    return series ? series[0].series.series_name : ''
  }

  getGridDefaultSampleText() {
    // グリット表示の初期表示を「フォント名」ではなく「サンプルテキスト」に
    // ▼該当言語 中国語(繁体字） 中国語(簡体字） 韓国語 アラビア語 ヘブライ語 タイ語 ヒンディ語 ベトナム語
    return [
      '0031',
      '0032',
      '0040',
      '0050',
      '0060',
      '0070',
      '0080',
      '0130'
    ].includes(this.language)
      ? this.sampleTextSelectOptions[1]
      : ''
  }

  getCatalogSampleText(font, pageType) {
    const seriesName = this.getSeriesName(font.font_series_id)
    let text = this.sampleText ? this.sampleText : seriesName

    if (
      this.hasBookmarkMultiLang &&
      !this.sampleText &&
      pageType === 'bookmark'
    ) {
      const data = this.sampleTextSelectData.languages.find(
        v => v.code === font.language
      )

      if (this.viewType === 'list') {
        text = data.phrases[0].text
      } else {
        text = seriesName
      }
    }

    return text
  }

  isPrimary(font) {
    const series = this.getSeriesFontsList(font.font_series_id)
    return series ? series[0].series.primary_font_id === font.font_id : false
  }

  updatePage(num = 0) {
    if (!num) return

    this.currentPage = num
    if (isPCLargeMax()) {
      window.scrollTo(0, 0)
    }
    this.updateFonts()
  }

  updateURL(type = 'history') {
    const payload = JSON.parse(JSON.stringify(this.fontsPayload))

    // URLに不要なプロパティーを削除
    delete payload.offset
    Object.keys(payload).forEach(key => {
      if (isEmpty(payload[key])) {
        delete payload[key]
      }
    })

    if (this.currentPage > 1) {
      payload.pages = this.currentPage
    }

    const params = qs.stringify(payload, true)

    if (type === 'history') {
      history.push(params)
    } else {
      location.href = params
    }
  }

  async updateFonts() {
    this.updateURL()
    this.httpState = 'loading'
    if (!isPCLargeMax()) {
      window.scrollTo(0, 0)
    }
    await wait(100)

    this.httpFonts = await cacheApi.getFonts(this.fontsPayload)
    this.httpState = 'ready'

    this.$nextTick(() => {
      applyFontHandler(this.$refs.fontSampleText)
    })
  }

  async updateBookmartFonts() {
    this.httpBookmarkFonts = await cacheApi.getFont({
      font_id: this.bookmarkIds
    })
  }

  updateFilterStyle() {
    const rect = this.$el.getBoundingClientRect()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const scrollBottom = scrollTop + window.innerHeight
    const bottom = rect.bottom + scrollTop

    const headerEl = document.querySelector(HEADER_SEARCH_CLASS)
    const importantEl = document.querySelector(IMPORTANT_SELECTOR)
    const searchEl = document.querySelector(FS_SEARCH_SELECTOR)
    let height = window.innerHeight

    if (headerEl) {
      height -= headerEl.clientHeight
    }
    if (importantEl) {
      height -= importantEl.clientHeight
    }
    if (searchEl) {
      height -= searchEl.clientHeight
    }
    if (scrollBottom > bottom && scrollBottom > window.innerHeight) {
      height -= scrollBottom - bottom
    }
    if (window.innerHeight > bottom) {
      height -= window.innerHeight - bottom
    }

    if (height > 0) {
      this.$refs.filter.style.height = isPCLargeMax() ? '' : `${height - 1}px`
    }
  }

  updateBookmartSampleText() {
    if (this.hasBookmarkMultiLang) {
      this.$nextTick(() => {
        const sampleTextEl = document.querySelectorAll(
          '.fs-main__bookmark .fs-catalog__sample a'
        )

        //
        ;[...sampleTextEl].map(el => {
          const lang = el.getAttribute('data-language')
          const data = this.sampleTextSelectData.languages.find(
            v => v.code === lang
          )

          if (this.viewType === 'list') {
            el.innerText = data.phrases[0].text
          } else {
            el.innerText = el.getAttribute('data-seriesName')
          }
        })

        applyFontHandler(this.$refs.fontName)
        applyFontHandler(this.$refs.fontSampleText)
      })
    }
  }

  clearPageNum() {
    this.currentPage = 1
    this.updateURL()
  }

  applyFontSampleText() {
    applyFontHandler(this.$refs.fontName)
    applyFontHandler(this.$refs.fontSampleText)

    // 開いているフォントスタイルパネルを検索しフォントを適応する
    if (this.$refs.stylePanel) {
      const opensStylePanel = this.$refs.stylePanel.filter(
        el => el.getAttribute('aria-hidden') === 'false'
      )
      opensStylePanel.map(el => {
        const fontId = el.dataset.id
        applyFontHandler(this.$refs[`styleSample-${this.pageType}-${fontId}`])
      })
    }
  }
}
</script>
