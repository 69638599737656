<script>
import { Component, Watch, Model, Vue } from 'vue-property-decorator'
import { debounce } from '~/common/utils'

import { BREAKPOINT_SP_S } from '~/common/config'
import { applyFontHandler } from '~/common/utils'
import * as dataApi from '~/client/dataApi'
import ScrollTo from '~/modules/ScrollTo'

const STYLESAMPLE_SELECTOR = '.js-styleSample'
const DEFAULT_FONT_SIZE = 56
const DEFAULT_FONT_SIZE_S = 16
const DEFAULT_LINE_HEIGHT = 20
const DEFAULT_BG_CLASS = 'bgRed'

@Component
export default class FsDetail extends Vue {
  // 言語コード
  language = '0010'

  // 詳細情報を開くか否か
  opensInfo = false

  // 字形jsonデータ
  shapeData = []

  // サンプルテキストjsonデータ
  sampleTextData = []

  // 字形タブで選択中のインデックス番号
  currentShapeTabIndex = 0

  // 機能タイプ
  functionType = 'spec'

  // フォントサイズのプルダウン選択
  @Model('change', { type: Number, default: DEFAULT_FONT_SIZE })
  fontSizeSelect

  // 字形のプルダウン選択
  @Model('change', { type: Number, default: 0 })
  shapeListSelect

  // サンプルテキストのプルダウン選択
  @Model('change', { type: Number, default: 0 })
  sampleTextSelect

  // サンプルテキストフォントサイズ
  @Model('change', { type: Number, default: DEFAULT_FONT_SIZE })
  sampleTextFontSize

  // サンプルテキストフォントサイズのプルダウン選択
  @Model('change', { type: Number, default: DEFAULT_FONT_SIZE })
  sampleTextFontSizeSelect

  // サンプルテキスト行間
  @Model('change', { type: Number, default: DEFAULT_LINE_HEIGHT })
  sampleTextLineHeight

  // サンプルテキスト行間のプルダウン選択
  @Model('change', { type: Number, default: DEFAULT_LINE_HEIGHT })
  sampleTextLineHeightSelect

  // 見出し、本文ラジオボタン
  @Model('change', { type: String, default: '0010' })
  uses

  // 機能詳細プルダウン
  @Model('change', { type: String, default: 'spec' })
  functionTypeSelect

  @Watch('currentSampleText')
  onCurrentSampleTextChanged(val) {
    this.$nextTick(() => {
      applyFontHandler()
    })
  }

  @Watch('shapeListSelect')
  onShapeListSelectChanged(val) {
    this.currentShapeTabIndex = val
  }

  @Watch('sampleTextSelect')
  onSampleTextSelectChanged(val) {
    this.$nextTick(() => {
      applyFontHandler()
    })
  }

  @Watch('sampleTextFontSize')
  onSampleTextFontSizeChanged(val) {
    this.sampleTextFontSizeSelect = val
  }

  @Watch('sampleTextFontSizeSelect')
  onSampleTextFontSizeSelectChanged(val) {
    this.sampleTextFontSize = val
  }

  @Watch('sampleTextLineHeight')
  onSampleTextLineHeightChanged(val) {
    this.sampleTextLineHeightSelect = val
  }

  @Watch('sampleTextLineHeightSelect')
  onSampleTextLineHeightSelectChanged(val) {
    this.sampleTextLineHeight = val
  }

  @Watch('uses')
  onUsesChanged(val) {
    this.sampleTextFontSize =
      val === '0010' ? DEFAULT_FONT_SIZE : DEFAULT_FONT_SIZE_S
    this.sampleTextFontSizeSelect =
      val === '0010' ? DEFAULT_FONT_SIZE : DEFAULT_FONT_SIZE_S
  }

  @Watch('functionTypeSelect')
  onFunctionTypeSelectChanged(val) {
    this.functionType = val
  }

  get shapeList() {
    if (this.shapeData.length === 0) {
      return []
    }

    const data = this.shapeData.languages.find(v => v.code === this.language)
    if (!data) {
      return []
    }

    return data.phrases
  }

  get currentShapeText() {
    return this.shapeList ? this.shapeList[this.currentShapeTabIndex].text : ''
  }

  get currentShapeTextTitle() {
    return this.shapeList ? this.shapeList[this.currentShapeTabIndex].title : ''
  }

  get sampleTextList() {
    if (this.sampleTextData.length === 0) {
      return []
    }

    const data = this.sampleTextData.languages.find(
      v => v.code === this.language
    )
    if (!data) {
      return []
    }

    return data.phrases
  }

  get currentSampleText() {
    return this.sampleTextList.length
      ? this.sampleTextList[this.sampleTextSelect].text
      : ''
  }

  get currentSampleTextTitle() {
    return this.sampleTextList.length
      ? this.sampleTextList[this.sampleTextSelect].title
      : ''
  }

  get sampleTextStyle() {
    return {
      fontSize: this.sampleTextFontSize ? `${this.sampleTextFontSize}px` : null,
      lineHeight:
        this.uses === '0020' ? `${this.sampleTextLineHeight / 10}` : null
    }
  }

  async mounted() {
    this.language = this.$el.dataset.lang
    this.shapeData = await dataApi.getShape()
    this.sampleTextData = await dataApi.getPhrases()

    new ScrollTo()
    this.eventHander()
    this.$nextTick(() => {
      const fontsearchEl = document.querySelector('.js-fontsearch')
      if (fontsearchEl) {
        fontsearchEl.classList.add('-mounted')
      }

      applyFontHandler()
    })
  }

  eventHander() {
    const mql = window.matchMedia(`(max-width: ${BREAKPOINT_SP_S}px)`)
    mql.addListener(this.onMatchMediaSPS.bind(this))
    this.onMatchMediaSPS(mql)
  }

  onMatchMediaSPS({ matches }) {
    this.sampleTextFontSize = matches ? false : DEFAULT_FONT_SIZE
  }
  /**
   * 詳細情報の開閉ボタンクリック時に呼ばれる関数
   */
  onInfoToggle() {
    this.opensInfo = !this.opensInfo
  }

  onClickShapeTabBtn(index = 0) {
    this.currentShapeTabIndex = index
  }

  /**
   * 機能詳細切り替えボタンクリック時に呼ばれる関数
   */
  onFunctionClick(type = 'spec') {
    this.functionType = type
  }

  /**
   * テキストを1文字づつ分割した配列にして返す関数
   * @param {string} str
   */
  getShapeTextArray(str = '') {
    const list = []
    for (let i = 0; i < str.length; i++) {
      list.push(str.charAt(i))
    }
    return list
  }
}
</script>
