import Swiper from 'swiper/dist/js/swiper.js'

import { BREAKPOINT_SP } from '~/common/config'
import { isSP } from '~/common/utils'

const WRAPPER_CLASS = 'swiper-wrapper'
const SLIDE_CLASS = 'swiper-slide'

/**
 * Swiper class
 * @description https://idangero.us/swiper/api/
 * @example
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide">Slide 1</div>
      <div class="swiper-slide">Slide 2</div>
      <div class="swiper-slide">Slide 3</div>
    </div>
    <div class="swiper-pagination"></div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
    <div class="swiper-scrollbar"></div>
  </div>

  const mySwiper = new Swiper('.swiper-container', {
    speed: 400,
    spaceBetween: 100
  });
 */
export default class SwiperSlider {
  /**
   * [constructor description]
   * @return {[type]} [description]
   */
  constructor(el) {
    this.el = el
    this.option = {
      speed: 600,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      on: {
        init: this.handlerInit.bind(this),
        slideChangeTransitionStart: this.handlerSlideChangeTransitionStart.bind(
          this
        )
      },
      syncEl: ''
    }
    this.syncEl = null

    this.init()
    this.eventHandler()
  }

  get getWrapperEl() {
    return this.el.querySelectorAll(`.${WRAPPER_CLASS}, ._${WRAPPER_CLASS}`)
  }

  get getSlideEl() {
    return this.el.querySelectorAll(`.${SLIDE_CLASS}, ._${SLIDE_CLASS}`)
  }

  /**
   * 初期設定関数
   * @return {[type]} [description]
   */
  init() {
    let option = ''

    // data-optionに設定されているオプションとデフォルトのオプションをマージする
    option = this.el.dataset.option
      ? new Function('return ' + this.el.dataset.option)()
      : {}
    this.option = Object.assign(this.option, option)

    this.swiper = new Swiper(this.el, this.option)

    if (!isSP() && this.option.isSP) {
      this.swiperInvalid()
    }

    const currentIndex =
      'initialSlide' in this.option ? this.option.initialSlide : 0
    this.el.classList.add(`-current-slide-${currentIndex}`)
  }

  eventHandler() {
    ;[...this.el.querySelectorAll('[data-goto]')].map(el => {
      el.addEventListener('click', this.handleGoToClick.bind(this))
    })

    const mql = window.matchMedia(`screen and (max-width: ${BREAKPOINT_SP}px)`)
    mql.addListener(this.handleMatchMedia.bind(this))
  }

  handlerInit() {}

  handlerSlideChangeTransitionStart() {
    this.switchCurrentClass()

    if (this.option.syncEl) {
      this.syncEl = document.querySelectorAll(this.option.syncEl)
      ;[...this.syncEl].map(el => {
        el.swiper.slideTo(this.swiper.activeIndex)
      })
    }
  }

  handleGoToClick(ev) {
    const index = ev.currentTarget.dataset.goto
    if (index) {
      this.swiper.slideTo(index)
    }
  }

  handleMatchMedia(ev) {
    this.swiper.destroy(true, true)
    this.swiperValid()
    this.init()
  }

  swiperInvalid() {
    this.swiper.destroy(true, true)

    const wrapperEl = this.getWrapperEl
    ;[...wrapperEl].map(el => el.classList.remove(WRAPPER_CLASS))
    ;[...wrapperEl].map(el => el.classList.add(`_${WRAPPER_CLASS}`))

    const slideEl = this.getSlideEl
    ;[...slideEl].map(el => el.classList.remove(SLIDE_CLASS))
    ;[...slideEl].map(el => el.classList.add(`_${SLIDE_CLASS}`))
  }

  swiperValid() {
    const wrapperEl = this.getWrapperEl
    ;[...wrapperEl].map(el => el.classList.add(WRAPPER_CLASS))
    ;[...wrapperEl].map(el => el.classList.remove(`_${WRAPPER_CLASS}`))

    const slideEl = this.getSlideEl
    ;[...slideEl].map(el => el.classList.add(SLIDE_CLASS))
    ;[...slideEl].map(el => el.classList.remove(`_${SLIDE_CLASS}`))
  }

  switchCurrentClass() {
    if (!this.swiper) return

    if ('previousIndex' in this.swiper) {
      this.el.classList.remove(`-current-slide-${this.swiper.previousIndex}`)
    }
    if ('activeIndex' in this.swiper) {
      this.el.classList.add(`-current-slide-${this.swiper.activeIndex}`)
    }
  }
}
