import Emitter from 'events'
import walk from 'dom-walk'
import domLoaded from 'dom-loaded'
import parseFont from 'css-font-parser'
import MutationObserver from 'mutation-observer'

import { EVENT_FONTPLUS_ONREADY } from '~/common/config'
import Font from './font'
import StyleSheet from './style_sheet'

function loadKey(host, code) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.addEventListener('load', resolve)
    xhr.addEventListener('error', reject)
    xhr.open('GET', `${host}/accessor/reqk?${code}`)
    xhr.send()
  })
}

const defaultOptions = {
  host: 'https://webfont.fontplus.jp',
  observeTarget: [document.body],
  applyAfterDOMContentLoaded: true
}

export default class Handler extends Emitter {
  constructor(options) {
    super()

    this.key = null
    this.options = { ...defaultOptions, ...options }

    if (!this.options.code) {
      throw new Error('options.code is required.')
    }

    this._fonts = new Map()
    this.observers = new WeakMap()

    this.exclude = []

    Promise.all([domLoaded, loadKey(this.options.host, this.options.code)])
      .then(this.onReady.bind(this))
      .catch(err => console.error(err))
  }

  onReady([, e]) {
    if (e.target.status !== 200) {
      return console.error(e.target)
    }
    this.key = JSON.parse(e.target.responseText).condition

    if (this.options.applyAfterDOMContentLoaded) {
      this.apply()
    }
    if (this.options.observeTarget && this.options.observeTarget.length) {
      this.options.observeTarget.forEach(this.observe, this)
    }

    this.emit(EVENT_FONTPLUS_ONREADY)
  }

  attach(options = { ...defaultOptions, ...this.options }) {
    // initialize CSSStyleSheet
    const style = document.createElement('style')
    // WebKit hack
    style.appendChild(document.createTextNode(''))
    document.head.appendChild(style)
    this.styleSheet = new StyleSheet(style)

    document.addEventListener(
      'DOMContentLoaded',
      this.onDOMContentLoaded,
      false
    )

    return this
  }

  fonts(name) {
    if (!this._fonts.has(name)) {
      this._fonts.set(name, new Font(this, name, this.styleSheet))
    }
    return this._fonts.get(name)
  }

  apply(el = document.body) {
    const map = new Map()

    // collect subsets from tree.
    walk(el, node => {
      if (node.nodeType !== Node.TEXT_NODE) return

      const parent = node.parentNode
      const text = node.textContent

      // if (['A', 'SCRIPT', 'SELECT', 'OPTION'].includes(parent.tagName)) return
      if (['SCRIPT', 'SELECT', 'OPTION'].includes(parent.tagName)) return

      const computedStyle = getComputedStyle(parent, '')

      const font = [
        computedStyle.getPropertyValue('font-size'),
        computedStyle.getPropertyValue('font-family')
      ].join(' ')

      const parsed = parseFont(font)
      const families = parsed ? parsed['font-family'] : [font]

      // console.debug(parent, text, font, families.length, families)

      families.forEach(name => {
        if (
          !name ||
          name === '"Hiragino Kaku Gothic ProN"' ||
          name === 'sans-serif' ||
          name === '""'
        ) {
          return
        }

        if (!map.has(name)) {
          map.set(name, [])
        }
        const target = map.get(name)
        target.push(text)
        map.set(name, target)
      }, this)
    })

    // apply fonts
    const result = []

    map.forEach((value, key) => {
      if (this.exclude.includes(key)) return
      result.push(this.fonts(key).load(value.join('')))
    }, this)

    return result
  }

  observe(el = document.body) {
    const observer = new MutationObserver(this.observeCallback.bind(this))

    observer.observe(el, {
      childList: true,
      attributes: true,
      characterData: true,
      subtree: true,
      attributeOldValue: true,
      characterDataOldValue: true
    })

    this.observers.set(el, observer)
  }

  observeCallback(mutations) {
    mutations.forEach(mutation => {
      const node = mutation.target

      if (mutation.addedNodes.length) {
        // mutation.addedNodes.forEach(this.apply, this)
        this.apply(mutation.target)
        return
      }

      if (mutation.type == 'attributes' && mutation.attributeName == 'style') {
        this.apply(mutation.target)
        return
      }

      if (node.nodeType == Node.TEXT_NODE) {
        this.apply(node.parentNode)
        return
      }
    })
  }

  disconnect(el) {
    if (!this.observers.has(el)) return
    const observer = this.observers.get(el)
    observer.disconnect()
    this.observers.delete(el)
  }
}
