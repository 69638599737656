import { Buffer } from 'buffer';

export const encode = (str, encoding = 'utf8') => {
  return Buffer.from(str, encoding)
    .toString('base64')
    .replace(/=+$/, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
};

export const decode = (str, encoding = 'utf8') => {
  const padding = '='.repeat(4 - Math.floor(str.length % 4));
  return Buffer.from(str.replace('-', '+').replace('_', '/') + padding, 'base64').toString(encoding);
};
