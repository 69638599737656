import SmoothScroll from 'smooth-scroll'
import { BREAKPOINT_SP, BREAKPOINT_PC_L } from '~/common/config'
import { eventFontApply } from '~/common/events'

const scroll = new SmoothScroll()

/**
 * 指定classの親要素を返す関数
 * @params { HTMLElement } el
 * @params { string } targetClass
 */
export const closest = (el, targetClass) => {
  for (let item = el; item; item = item.parentElement) {
    if (item.classList.contains(targetClass)) {
      return item
    }
  }
}

export const offsetTop = el => {
  const rect = el.getBoundingClientRect()
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return rect.top + scrollTop
}

/**
 * 画面サイズがSPか否か
 * @return boolean
 */
export const isSP = () => {
  return window.innerWidth <= BREAKPOINT_SP
}

/**
 * 画面サイズがBREAKPOINT_PC_Lよりも小さいか否か
 * @return boolean
 */
export const isPCLargeMax = () => {
  return window.innerWidth <= BREAKPOINT_PC_L
}

/**
 * 遅延関数
 * @param {number} delay
 */
export const wait = (delay = 0) => {
  return new Promise(resolve => setTimeout(resolve, delay))
}

/**
 * スムーズスクロール
 * Doc: https://www.npmjs.com/package/smooth-scroll#animatescroll
 * @param {number | object} target
 */
export const smoothScroll = (target = 0) => {
  scroll.animateScroll(target)
}

/**
 * webfontを反映するイベントを呼ぶ関数
 */
export const applyFontHandler = (el = document.body) => {
  if (Array.isArray(el)) {
    if (el.lenght === 0) return
    el.map(_el => {
      eventFontApply(_el)
    })
  } else {
    eventFontApply(el)
  }
}

// debounce
// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_debounce
export const debounce = function (func, wait, immediate) {
  var timeout;
  return function() {
  	var context = this, args = arguments;
  	clearTimeout(timeout);
  	timeout = setTimeout(function() {
  		timeout = null;
  		if (!immediate) func.apply(context, args);
  	}, wait);
  	if (immediate && !timeout) func.apply(context, args);
  };
}

// isEmpty
// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_isempty
export const isEmpty = obj => [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length;
